import { Platforms, SubscriptionTypes, AddonTypes } from '@internal/tv-auth';

export enum RoomType {
    Standard = 0,
    Community = 1,
}

export type Room = {
    id: string;
    urlName: string;
    name: string;
    userID: string;
    roomType: RoomType;
    machineID: string;
    createdOn: string;
    deletedOn: string;
    participantCount: number;
    totalMemberCount: number;
    lastContentStream: ContentStream;
    roomServerWSURL: string;
    members: User[];
    activeMembers: User[];
    settings: RoomSettings;
    permissions: UserPermission[];
    maxSubs: number;
};

export type RoomSettings = {
    openSpeak: boolean;
    openStream: boolean;
};

export type RoomConfig = {
    settings: RoomSettings;
    permissions: UserPermission[];
};

export type ScheduledSession = {
    id: string;
    title: string;
    description: string;
    startTime: number;
    started: boolean;
};

export type ContentStream = {
    id: string;
    contentName: string;
    contentPlatform: string;
    contentURL: string;
    thumbnailURL: string;
    width: number;
    height: number;
    streamedOn: string;
    stoppedOn: string;
};

export type User = {
    id: string;
    email: string;
    magicIssuer: string;
    name: string;
    createdOn: string;
    updatedOn: string;
    deletedOn: string;
    avatar: UserAvatar;
    creator: boolean;
};

export type UserUsage = {
    roomJoinsCount: number;
};

export enum PermissionType {
    Speak = 1,
    Stream = 2,
    StageInvite = 3,
    Ban = 4,
    Mute = 5,
    Settings = 6,
    ChangeName = 7,
    RoleGranter = 8,
}

export type UserPermission = {
    userID: string;
    roomID: string;
    permission: PermissionType;
    enabled: boolean;
};

export type UserAvatar = {
    id: string;
    userID: string;
    url: string;
    size: Number;
};

export type MachineInfo = {
    id?: string;
    machineId?: string;
    version: Number;
    cpuManufacturer: string;
    cpuBrand: string;
    cpuVendor: string;
    cpuFamily: string;
    cpuModel: string;
    cpuCores: Number;
    cpuPhysicalCores: Number;
    cpuProcessors: Number;
    cpuSpeed: Number;
    cpuSpeedMin: Number;
    cpuSpeedMax: Number;
    totalMem: Number;
    osInfoPlatform: string;
    osInfoDistro: string;
    osInfoRelease: string;
    osInfoArch: string;
    osInfoKernel: string;
    osInfoCodename: string;
    systemModel: string;
    systemManufacturer: string;
    systemVersion: string;
};

export enum NotificationType {
    StreamStart = 1,
    StageStart = 2,
    ChatMention = 3,
}

export enum NotificationChannelType {
    Push = 1,
    Email = 2,
}

export type UserNotificationOptOut = {
    id: string;
    userID: string;
    roomID: string;
    notification: NotificationType;
    channel: NotificationChannelType;
};

export type Recording = {
    id: string;
    roomId: string;
    room: Room;
    url: string;
    gcsPath: string;
    fileSize: number;
    createdOn: string;
    startedOn: string;
    endedOn: string;
};

export enum TVAuthStatus {
    Success = 1,
    Fail = 2,
    Invalid = 3,
    UsernameInvalid = 4,
    PasswordInvalid = 5,
    PortalTimeout = 6,
    PlatformError = 7,
}

export type TVAuthAttempt = {
    id: string;
    userID: string;
    platform: Platforms;
    username: string;
    location: string;
    status: TVAuthStatus;
    createdOn: string;
    updatedOn: string;
};

export type TVAuthSubscription = {
    id: string;
    userID: string;
    attemptID: string;
    platform: Platforms;
    username: string;
    location: string;
    subscription: SubscriptionTypes;
    createdOn: string;
};

export type TVAuthAddon = {
    id: string;
    subscriptionID: string;
    addon: AddonTypes;
    createdOn: string;
};
